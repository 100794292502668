import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button, TYPES } from '../../modules/button';
import Modal from '../../modules/modal';
import { Row } from '../../modules/elements';
import { Input } from '../../modules/input';
import { createResource } from '../../utils/voteApi';
import Translation from '../../translation/lang.json';
import Category from './category';
import useGetCategoriesForUser from '../../hooks/useGetCategoriesForUser';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const CreateNew = () => {
  const history = useHistory();
  const [modalShown, toggleModal] = useState(false);
  const [category, setCategory] = useState(null);
  // const [showCategory, setShowCategory] = useState(false);
  const topic = useRef(null);
  const userCategories = useGetCategoriesForUser();

  const openModal = () => toggleModal(true);
  const closeModal = () => toggleModal(false);

  const startNewSession = async () => {
    const generatedId = await createResource(topic.current.value, category);
    history.push(`/vote/${generatedId}`);
  };

  // const onShowCategoryClicked = () => setShowCategory(true);

  return (
    <Container>
      <Button action={ TYPES.PRIMARY } onClick={ openModal }>
        {Translation.se.createNewVote.createNew}
      </Button>
      <Container>
        { modalShown &&
            <Modal>
              <Input ref={ topic } placeholder={ Translation.se.createNewVote.topic }/>
              <Category
                setCategoryCallback={ setCategory }
                categories = { userCategories }
              />
              <Row>
                <Button action={ TYPES.PRIMARY } onClick={ startNewSession }> {Translation.se.createNewVote.start} </Button>
                <Button action={ TYPES.SECONDARY } onClick={ closeModal }> {Translation.se.createNewVote.cancel} </Button>
              </Row>
            </Modal>
        }
      </Container>
    </Container>
  );
};

export default CreateNew;
