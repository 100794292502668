import styled from 'styled-components';

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
`;

export const Column = styled(Row)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CenteredColumnContainer = styled(Column)`
  min-height: 80vh;
`;

export const InfoText = styled.p`
  font-size: 16px;
`;
