import React, { useRef, useState, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { UserContext } from '../../contexts/userContext';
import { FormButton, TYPES } from '../../modules/button';
import { Column, Row, InfoText } from '../../modules/elements';
import { Input } from '../../modules/input';
import { loginUser, createUser } from '../../utils/userApi';
import styled from 'styled-components';
import Translation from '../../translation/lang.json';

const Form = styled.form`
  width: 100%;
  max-width: 400px;
`;

const ForgotPasswordContainer = styled.div`
  margin: 1rem;
`;

const Login = () => {
  const history = useHistory();
  const email = useRef(null);
  const password = useRef(null);
  const [message, setMessage] = useState(null);
  const { setUserContext } = useContext(UserContext);

  const submitLogin = async (e) => {
    setMessage(null);
    e.preventDefault();
    const res = await loginUser(email.current.value, password.current.value);
    if (res.error) setMessage(res.error);
    if (res.user) {
      await setUserContext(res.user);
      history.replace('/');
    }
  };

  const submitCreate = async (e) => {
    setMessage(null);
    e.preventDefault();
    const createRes = await createUser(email.current.value, password.current.value);
    if (createRes.success) {
      setMessage(createRes.success);
    } else setMessage(createRes.error);
  };

  return (
    <Column>
      <Form onSubmit={ () => false }>
        <Row>
          <Input
            tabIndex={ 1 }
            ref={ email }
            type="email"
            name="email"
            placeholder={ Translation.se.login.email }/>
        </Row>
        <Row>
          <Input
            tabIndex={ 2 }
            ref={ password }
            type="password"
            name="password"
            placeholder={ Translation.se.login.password }/>
        </Row>
        <Row>
          <FormButton tabIndex={ 3 } type="submit" action={ TYPES.PRIMARY } onClick={ submitLogin } value={ Translation.se.login.login }/>
          <FormButton tabIndex={ 4 } type="submit" action={ TYPES.SECONDARY } onClick={ submitCreate } value={ Translation.se.login.createUser }/>
        </Row>
        <Row>
          <ForgotPasswordContainer>
            <Link to={ { pathname: '/forgot-password' } }> { Translation.se.login.forgotPassword }</Link>
          </ForgotPasswordContainer>
        </Row>
      </Form>
      <Row>
        <InfoText>
          { message }
        </InfoText>
      </Row>
    </Column>
  );
};

export default Login;
