export const calculateVotes = (data) => data.very_sad_count + data.sad_count + data.happy_count + data.very_happy_count;

export const calculateAverageSentiment = (data) => {
  const totalCount = calculateVotes(data);
  const totalRating =
      (data.very_sad_count * 1) +
      (data.sad_count * 2) +
      (data.happy_count * 3) +
      (data.very_happy_count * 4);
  return totalRating / totalCount;
};

export const calculateWeekNumber = (date) => {
  var tdt = new Date(date.valueOf());
  var dayn = (date.getDay() + 6) % 7;
  tdt.setDate(tdt.getDate() - dayn + 3);
  var firstThursday = tdt.valueOf();
  tdt.setMonth(0, 1);
  if (tdt.getDay() !== 4) {
    tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
  }
  return 1 + Math.ceil((firstThursday - tdt) / 604800000);
};
