import styled from 'styled-components';
import { Column, Row } from '../../modules/elements';
import { Headline1 } from '../../style/texts';
import { DEVICES } from '../../style/devices';

export const TextContainer = styled.div`
    margin: 1rem;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;
`;

export const TopRow = styled(Column)`
    justify-content: flex-end;
    align-items: flex-end;
`;

export const BottomRow = styled(Row)`
    position: relative;
    top: 0;
    justify-content: center;
    align-items: center;
`;

export const IconContainer = styled.div`
  cursor: pointer;

  &:not(:active) {
      transition: transform 0.3s step-end;
  }

  &:active {
      transform: rotate(180deg);
  }

  i {
    font-size: 4rem;
    padding: 0.5rem;

    @media ${DEVICES.tablet}{
      font-size: 8rem;
      padding: 1rem;
    }
  }
`;

export const Sound = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
`;

export const Headline = styled(Headline1)`
  display: inline-block;
  margin: 0 1rem;
`;
