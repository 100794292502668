import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input } from '../../modules/input';
import Dropdown from '../../modules/dropdown';
import { UnstyledButton } from '../../modules/button';
import Translation from '../../translation/lang.json';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Category = ({ setCategoryCallback, initialValue, categories }) => {
  const [createNewCategory, setCreateNewCategory] = useState(!(categories?.length > 0));

  const onClickToggleNewCategory = () => {
    if (categories?.length > 0) {
      setCreateNewCategory(!createNewCategory);
    } else {
      setCreateNewCategory(true);
    }
  };
  const newCategory = (e) => setCategoryCallback(e.currentTarget.value);
  const onChangedSelectedCategory = (value) => {
    setCategoryCallback(value);
  };

  useEffect(() => setCategoryCallback(initialValue), [categories]);

  return (
    <Container>
      { (!createNewCategory && categories) &&
        <Dropdown
          onSelectionChanged={ onChangedSelectedCategory }
          options={ categories }
          initialValue={ initialValue }
        />
      }
      { createNewCategory &&
        <Input
          type={ 'text' }
          onChange={ newCategory }
          placeholder={ initialValue ?? Translation.se.createNewVote.category }
        />
      }
      { !createNewCategory &&
            <UnstyledButton onClick={ onClickToggleNewCategory }>
              { Translation.se.createNewVote.newCategory }
            </UnstyledButton>
      }
      { createNewCategory && categories?.length > 0 &&
            <UnstyledButton onClick={ onClickToggleNewCategory }>
              { Translation.se.createNewVote.existingCategory }
            </UnstyledButton>
      }
    </Container>
  );
};

Category.propTypes = {
  setCategoryCallback: PropTypes.func,
  initialValue: PropTypes.string,
  categories: PropTypes.array
};

export default Category;
