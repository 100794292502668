import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Column, Row } from '../../modules/elements';
import { User, Exit } from '../../modules/icons';
import { useHistory } from 'react-router-dom';
import { logout } from '../../utils/userApi';
import { COLORS } from '../../style/colors';
import Translation from '../../translation/lang.json';
import { DEVICES } from '../../style/devices';

const Button = styled.button`
  background-color: inherit;
  display: flex;
  align-items: center;
  border: none;
  &:hover{
    cursor: pointer;
  }

  & > span {
      display: none;
    }

  @media ${DEVICES.tablet}{
    & > span {
      display: block;
    }
  }
`;

const SidePanel = styled(Column)`
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  background-color: ${COLORS.LIGHT_GREY_BACKGROUND};
  max-width: 25rem;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Item = styled(Row)`
  max-width: 80%;
  margin: 1rem 2rem;
  height: 2rem;
  justify-content: flex-start;
`;

const ClickableItem = styled(Item)`
  &:hover{
    cursor: pointer;
    border-bottom: solid 1px black;
  }
`;

const ItemArea = styled(Column)`
  justify-content: flex-start;
  align-items: flex-start;
  margin: 4rem 0;
`;

const PanelHeader = styled(Row)`
  justify-content: flex-start;
  padding: 2rem;
`;

const Options = ({ user }) => {
  const [showOptions, setShowOptions] = useState(false);
  const history = useHistory();
  // if (!user) return null;

  const openPanel = () => setShowOptions(true);
  const closePanel = () => setShowOptions(false);

  const navigate = (path) => {
    closePanel();
    history.push(path);
  };
  const clickStatistics = () => navigate('/statistics');
  const clickChangePassword = async () => navigate('/change-password');

  const clickLogout = async () => {
    await logout();
    window.location.reload(false);
  };

  return (
    <>
      <Button onClick={ openPanel }>
        <User style={ { marginRight: '0.5rem' } }/>
        <span> { user.email } </span>
      </Button>
      { showOptions &&
        <SidePanel>
          <PanelHeader>
            <Button onClick={ closePanel }>
              <Exit />
            </Button>
          </PanelHeader>
          <ItemArea>
            <Item> { user.email } </Item>
            <ClickableItem onClick={ clickStatistics }> { Translation.se.sidePanel.statistics } </ClickableItem>
            <ClickableItem onClick={ clickLogout }> { Translation.se.sidePanel.logOut } </ClickableItem>
            <ClickableItem onClick={ clickChangePassword }> { Translation.se.sidePanel.changePassword }</ClickableItem>
          </ItemArea>
        </SidePanel>
      }
    </>
  );
};

Options.propTypes = {
  user: PropTypes.object
};

export default Options;
