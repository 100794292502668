import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Start from './components/start';
import Login from './components/login';
import RateSession from './components/vote';
import Summary from './components/summary';
import ForgotPassword from './components/password/forgot-password';
import Header from './components/header';
import Statistics from './components/statistics';
import ChangePassword from './components/password/change-password';
import NewPassword from './components/password/new-password';
import VerifyUser from './components/verify-user';
import PrivateRoute from './components/private-route';
import { UserContextProvider } from './contexts/userContext';
import './App.css';

export const UserContext = React.createContext({});

function App () {
  return (
    <UserContextProvider>
      <div className="App">
        <header className="App-header">
          <Header/>
        </header>
        <div className="App-content">
          <Switch>
            <Route path={ '/login' } >
              <Login/>
            </Route>
            <Route path={ '/verify-user/:userEmail?/:verificationToken?' } >
              <VerifyUser/>
            </Route>
            <Route path={ '/vote/:id' }>
              <RateSession />
            </Route>
            <Route path={ '/summary/:id' }>
              <Summary />
            </Route>
            <Route path={ '/forgot-password' }>
              <ForgotPassword />
            </Route>
            <Route path={ '/new-password/:userEmail?/:passwordResetToken?' } >
              <NewPassword/>
            </Route>
            <PrivateRoute path={ '/statistics' }>
              <Statistics />
            </PrivateRoute>
            <PrivateRoute path={ '/change-password' }>
              <ChangePassword />
            </PrivateRoute>
            <PrivateRoute path={ '/statistics' }>
              <Statistics/>
            </PrivateRoute>
            <Route path={ '/' } >
              <Start />
            </Route>
          </Switch>
        </div>
      </div>
    </UserContextProvider>
  );
}

export default App;
