import styled, { css } from 'styled-components';

const InputBase = css`
  padding: 1rem;
  font-size: 16px;
  margin: 1rem 0;
  width: 100%;
  border-radius: 2px;
  border-width: 1px;
  box-sizing: border-box;
`;

export const Input = styled.input`
  ${InputBase}
`;

export const Select = styled.select`
  ${InputBase}
`;
