import { useState, useEffect, useContext } from 'react';
import { getSessionsForUser } from '../utils/voteApi';
import { UserContext } from '../contexts/userContext';

const useGetSessionsForUser = (category) => {
  const [sessions, setSessions] = useState(null);
  const { user } = useContext(UserContext);

  const fetch = async (category) => {
    const statistics = await getSessionsForUser(category);
    setSessions([...statistics]);
  };

  useEffect(() => {
    const getStatistics = async () => {
      await fetch(category);
    };
    if (user) {
      getStatistics();
    }
  }, [user]);

  return { refetch: fetch, sessions };
};

export default useGetSessionsForUser;
