import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { verifyUser } from '../../utils/userApi';

const VerifyUser = () => {
  const { userEmail, verificationToken } = useParams();
  const [message, setMessage] = useState('Verifying user...');

  useEffect(() => {
    const tryVerify = async () => {
      const { success, error } = await verifyUser(userEmail, verificationToken);
      if (success) setMessage(success);
      else setMessage(error);
    };

    tryVerify();
  });

  return (
    <div>
      { message }
    </div>
  );
};

export default VerifyUser;
