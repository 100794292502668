import React, { useState } from 'react';
import { Select } from '../../modules/input';
import PropTypes from 'prop-types';

const renderOptions = (options) => options.map((option, index) =>
  <option key={ index } value={ option.value }>
    { option.text }
  </option>
);

const Dropdown = ({ options, onSelectionChanged, initialValue }) => {
  const [value, setValue] = useState(initialValue ?? (options.length && options[0].value));
  const onChange = (event) => {
    setValue(event.target.value);
    onSelectionChanged(event.target.value);
  };
  return (
    <Select onChange={ onChange } value={ value }>
      { options && renderOptions(options) }
    </Select>
  );
};

Dropdown.propTypes = {
  options: PropTypes.array,
  onSelectionChanged: PropTypes.func,
  initialValue: PropTypes.any
};

export default Dropdown;
