import * as d3 from 'd3';

export const generateD3PieChart = (svgRef, formattedData) => {
  const svg = d3.select(svgRef.current);
  const height = svgRef.current.clientHeight;
  const width = svgRef.current.clientWidth;
  const radius = Math.min(width, height) / 4;
  const g = svg.append('g').attr('transform', `translate(${(width / 2)},${(height / 2)})`);
  const color = d3.scaleOrdinal(['#ff0000', '#ffa500', '#90ee90', '#008000']);

  const pie = d3.pie()
    .value((d) => d.count);
  const path = d3.arc()
    .innerRadius(0)
    .outerRadius(radius);

  const label = d3.arc()
    .innerRadius(radius)
    .outerRadius(radius * 1.5);

  const arcs = g.selectAll('arc')
    .data(pie(formattedData), (d) => d.data.id)
    .enter()
    .append('g')
    .attr('class', 'arc');

  arcs.append('path')
    .attr('fill', function (d, i) {
      return color(i);
    })
    .attr('d', path);

  arcs.append('text')
    .attr('id', function (d) {
      return `${d.data.id}`;
    })
    .attr('transform', function (d) {
      return `translate(${label.centroid(d)})`;
    })
    .style('font-size', '16px')
    .text(function (d) {
      if (d.data.count > 0) {
        return `(${d.data.count})`;
      }
    });
};
