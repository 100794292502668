import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { UserContext } from '../../contexts/userContext';
function PrivateRoute ({ children, ...rest }) {
  const { user } = useContext(UserContext);

  if (!user) return 'Vänligen logga in igen för att se denna sida';

  return (
    <Route
      { ...rest }
      render={ ({ location }) =>
        (children)
      }
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.object
};

export default PrivateRoute;
