import styled from 'styled-components';
import { Column, Row } from '../../modules/elements';
import { COLORS } from '../../style/colors';
import { UnstyledButton } from '../../modules/button';
import { DEVICES } from '../../style/devices';

export const CategoryButton = styled(UnstyledButton)`
  margin: 0;
`;

export const Container = styled(Column)`
  padding: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const TopRow = styled(Row)`
  margin: 0 0 3rem 0;
`;

export const Table = styled.table`
  width: 100%;
  max-width: 600px;
  table-layout: fixed;
  border-collapse: collapse;
`;

export const Tr = styled.tr`
  height: 4rem;

  &:nth-child(odd){
    background-color: ${COLORS.LIGHT_GREY_BACKGROUND};
  }
`;

export const Th = styled.th`
  padding: 1rem;
  text-align: left;
  font-weight: normal;
`;

export const Td = styled.td`
  padding: 0;
  text-align: left;
  font-weight: normal;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  
  i{
    font-size: 1.5rem;
  }

  @media ${DEVICES.tablet}{
    padding: 0 1rem;
    font-size: 16px;
    i{
      font-size: 2.5rem;
    }
  }
`;
