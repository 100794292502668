import React from 'react';
import styled from 'styled-components';
import { Column } from '../../modules/elements';
import { COLORS } from '../../style/colors';
import PropTypes from 'prop-types';

const Container = styled(Column)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    padding: 2rem;
    max-width: 400px;
    width: 100%;
    background-color: ${COLORS.PRIMARY_LIGHT};
`;

const Background = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.7);
`;

const Modal = ({ children }) => {
  return (
    <Background>
      <Container>
        { children }
      </Container>
    </Background>
  );
};

Modal.propTypes = {
  children: PropTypes.node
};

export default Modal;
