import React, { useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import { Button, TYPES } from '../../modules/button';
import { Row, Column } from '../../modules/elements';
import { Input } from '../../modules/input';
import { updatePassword } from '../../utils/userApi';
import { UserContext } from '../../contexts/userContext';

const Container = styled(Column)`
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 4rem 0;
`;

const Element = () => {
  const [feedback, setFeedback] = useState(null);
  const oldPassword = useRef(null);
  const newPassword = useRef(null);
  const newPasswordRepeat = useRef(null);
  const { user } = useContext(UserContext);

  const handleUpdate = async () => {
    setFeedback(null);
    const newPass = newPassword.current.value;
    const newPassRepeat = newPasswordRepeat.current.value;
    const oldpass = oldPassword.current.value;
    if (newPass !== newPassRepeat) {
      setFeedback('New password is not the same');
      return;
    }
    const { response } = await updatePassword(oldpass, newPassRepeat);
    setFeedback(response);
  };

  if (!user) return null;

  return (
    <Container>
      <Column style={ { maxWidth: '400px' } }>
        <Input ref={ oldPassword } placeholder="Nuvarande lösenord" type="password"/>
        <Input ref={ newPassword } placeholder="Nytt lösenord" type="password"/>
        <Input ref={ newPasswordRepeat } placeholder="Upprepa nytt lösenord" type="password"/>
        <Row>
          <Button type="submit" action={ TYPES.PRIMARY } onClick={ handleUpdate }> Uppdatera lösenord </Button>
        </Row>
        {feedback}
      </Column>
    </Container>
  );
};

export default Element;
