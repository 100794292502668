import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DEVICES } from '../../style/devices';
import { IconVeryHappy, IconHappy, IconSad, IconVerySad } from '../../modules/icons';
import { generateD3PieChart } from './d3';

const PieSvg = styled.svg`
  width: 300px;
  height: 300px;
  @media ${DEVICES.tablet}{
    width: 500px;
    height: 500px;
  }
  @media ${DEVICES.desktop}{
    width: 800px;
    height: 800px;
  }
`;

const IconOverlay = styled.span`
  position: fixed;
  font-size: 1rem;
  top: ${props => props.top && (props.top) + 'px'};
  left: ${props => props.left && (props.left - 36) + 'px'};
  @media ${DEVICES.tablet}{
    top: ${props => props.top && (props.top - 16) + 'px'};
    left: ${props => props.left && (props.left - 64) + 'px'};
    padding: 0.5rem;
    font-size: 2rem;
  }
`;

const dataIds = {
  VERY_SAD: 'very_sad',
  SAD: 'sad',
  HAPPY: 'happy',
  VERY_HAPPY: 'very_happy'
};

const initialDataPointPositions = {
  [dataIds.VERY_SAD]: null,
  [dataIds.SAD]: null,
  [dataIds.HAPPY]: null,
  [dataIds.VERY_HAPPY]: null
};

const icons = {
  [dataIds.VERY_SAD]: <IconVerySad />,
  [dataIds.SAD]: <IconSad />,
  [dataIds.HAPPY]: <IconHappy />,
  [dataIds.VERY_HAPPY]: <IconVeryHappy/>
};

function debounce (callback, delay) {
  var timeoutHandler = null;
  return function () {
    clearTimeout(timeoutHandler);
    timeoutHandler = setTimeout(function () {
      callback();
    }, delay);
  };
}

const updateDataPointPositions = (formattedData, initialValue) => {
  if (!formattedData) return null;
  return formattedData.reduce((accumulator, dataPoint) => {
    if (dataPoint.count > 0) {
      const dataPointAnchor = document.getElementById(dataPoint.id);
      if (!dataPointAnchor) return { ...accumulator };
      const position = dataPointAnchor.getBoundingClientRect();
      return { ...accumulator, [dataPoint.id]: { top: position.top, left: position.left } };
    }
    return { ...accumulator };
  }, { ...initialValue });
};

const PieChart = ({ data }) => {
  const pieSvg = useRef(null);
  const [dataPointPositions, setDataPointPositions] = useState(initialDataPointPositions);

  const formattedData = useMemo(() => {
    return [
      { id: dataIds.VERY_SAD, count: data.very_sad_count },
      { id: dataIds.SAD, count: data.sad_count },
      { id: dataIds.HAPPY, count: data.happy_count },
      { id: dataIds.VERY_HAPPY, count: data.very_happy_count }
    ];
  }, [data]);

  const renderTooltips = (dataPointPositions, dataIds) => {
    return Object.values(dataIds).map((id, index) => {
      const pos = dataPointPositions[id];
      if (!pos) return null;
      return (
        <IconOverlay
          key={ index }
          top={ dataPointPositions[id].top }
          left={ dataPointPositions[id].left }
        >
          { icons[id] }
        </IconOverlay>
      );
    });
  };

  const recalculatePositions = useCallback(
    debounce(() => {
      if (formattedData) {
        const positions = updateDataPointPositions(formattedData, initialDataPointPositions);
        setDataPointPositions(positions);
      }
    }, 25), [formattedData]);

  useEffect(() => {
    window.addEventListener('resize', recalculatePositions);
    window.addEventListener('scroll', recalculatePositions);
    return () => {
      window.removeEventListener('resize', recalculatePositions);
      window.removeEventListener('scroll', recalculatePositions);
    };
  }, [formattedData, recalculatePositions]);

  useEffect(() => {
    if (pieSvg.current && data) {
      const dataCopy = [...formattedData];
      generateD3PieChart(pieSvg, dataCopy);
      const positions = updateDataPointPositions(dataCopy, initialDataPointPositions);
      setDataPointPositions(positions);
    }
  }, [data, formattedData]);

  return (
    <>
      <PieSvg ref={ pieSvg } />
      { dataPointPositions && renderTooltips(dataPointPositions, dataIds) }
    </>
  );
};

PieChart.propTypes = {
  data: PropTypes.object
};

export default PieChart;
