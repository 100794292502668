import { useState, useEffect } from 'react';
import { getResource } from '../utils/voteApi';

const useGetSessionById = (sessionId) => {
  const [session, setSession] = useState(null);

  useEffect(() => {
    const getSession = async () => {
      const sessionData = await getResource(sessionId);
      setSession(sessionData);
    };
    if (sessionId) {
      getSession();
    }
  }, [sessionId]);

  return session;
};

export default useGetSessionById;
