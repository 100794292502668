import React from 'react';
import PropTypes from 'prop-types';
import { VolumeUp, VolumeOff, Done } from '../../modules/icons';
import { Link } from 'react-router-dom';
import { TopRow, Container, Sound, Headline } from './styled';

const VoteHeader = ({ numVotes, sound, toggleSound, topic, dataId }) => {
  const toggleSoundActive = () => toggleSound(!sound);

  if (!dataId) return null;

  const renderSoundIcon = (sound) => {
    if (sound) return <VolumeUp/>;
    return <VolumeOff/>;
  };

  return (
    <TopRow>
      <Container>
        <Sound onClick={ toggleSoundActive }>
          { renderSoundIcon(sound) }
        </Sound>
      </Container>
      <Link to={ `/summary/${dataId}` }>
        <Container>
          <Headline>
            { topic } ({ numVotes })
          </Headline>
          <Done />
        </Container>
      </Link>
    </TopRow>
  );
};

VoteHeader.propTypes = {
  numVotes: PropTypes.number.isRequired,
  sound: PropTypes.bool.isRequired,
  toggleSound: PropTypes.func.isRequired,
  topic: PropTypes.string.isRequired,
  dataId: PropTypes.string.isRequired
};

export default VoteHeader;
