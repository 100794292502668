const HOST = process.env.REACT_APP_SERVER_HOST;

export const loginUser = async (email, password) => {
  const data = new URLSearchParams();
  data.append('email', email);
  data.append('password', password);
  return await fetch(HOST + '/user/login',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: data.toString(),
      credentials: 'include',
      json: true
    })
    .then(res => res.json());
};

export const isLoggedIn = async () => {
  return await fetch(HOST + '/user/is_authenticated',
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
    .then(res => res.json())
    .catch(err => console.error(err));
};

export const logout = async () => {
  return await fetch(HOST + '/user/logout',
    {
      method: 'GET',
      credentials: 'include'
    })
    .then(res => res.json())
    .catch(err => console.error(err));
};

export const createUser = async (email, password) => {
  const data = new URLSearchParams();
  data.append('email', email);
  data.append('password', password);
  return await fetch(HOST + '/user/create-user',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: data.toString(),
      json: true
    })
    .then(res => res.json());
};

export const updatePassword = async (oldPassword, newPassword) => {
  const data = new URLSearchParams();
  data.append('oldPassword', oldPassword);
  data.append('newPassword', newPassword);
  return await fetch(HOST + '/user/update-password',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: data.toString(),
      json: true,
      credentials: 'include'
    })
    .then(res => res.json());
};

export const verifyUser = async (userEmail, verificationToken) => {
  const data = new URLSearchParams();
  data.append('userEmail', userEmail);
  data.append('verificationToken', verificationToken);
  return await fetch(HOST + '/user/verify-user',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: data.toString(),
      json: true
    })
    .then(res => res.json());
};

export const initiateReset = async (userEmail) => {
  const data = new URLSearchParams();
  data.append('userEmail', userEmail);
  return await fetch(HOST + '/user/initiate-reset',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: data.toString(),
      json: true
    })
    .then(res => res.json());
};

export const resetPassword = async (userEmail, passwordResetToken, newPassword) => {
  const data = new URLSearchParams();
  data.append('userEmail', userEmail);
  data.append('passwordResetToken', passwordResetToken);
  data.append('newPassword', newPassword);
  return await fetch(HOST + '/user/update-reset-password',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: data.toString(),
      json: true
    })
    .then(res => res.json());
};
