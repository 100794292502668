import styled, { css } from 'styled-components';
import { COLORS } from '../../style/colors';

export const TYPES = {
  PRIMARY: {
    backgroundColor: COLORS.PRIMARY_DARK,
    color: COLORS.PRIMARY_LIGHT
  },
  SECONDARY: {
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.PRIMARY_DARK
  }
};

export const UnstyledButton = styled.button`
    border: 0;
    background: none;
    cursor: pointer;
    color: ${COLORS.LINK_BLUE};
    margin-bottom: 1rem;
    display: block;
    align-self: flex-start;
    font-size: inherit;
`;

export const ButtonStyle = css`
   background-color: ${props => props.action ? props.action.backgroundColor : COLORS.PRIMARY_DARK};
   border: 1px solid ${props => props.type ? props.action.color : COLORS.PRIMARY_DARK};
   color: ${props => props.action ? props.action.color : COLORS.PRIMARY_LIGHT};
   padding: 1rem;
   font-size: 1rem;
   border-radius: 4px;
   margin: 0.5rem;
   display: flex;
   align-items: center;
 
   &:hover{
     background-color: ${COLORS.PRIMARY_LIGHT};
     color: black;
     cursor: pointer;
   }
`;

export const Button = styled.button`
  ${ButtonStyle};
 `;

export const FormButton = styled.input`
  ${ButtonStyle};
`;
