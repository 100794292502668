import React from 'react';
import { Row, CenteredColumnContainer } from '../../modules/elements';
import CreateNew from '../create-new';

const Start = () => {
  return (
    <CenteredColumnContainer>
      <Row>
        <CreateNew />
      </Row>
    </CenteredColumnContainer>
  );
};

export default Start;
