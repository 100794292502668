import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Row } from '../../modules/elements';
import Options from './options';
import Logo from './logo';
import { DEVICES } from '../../style/devices';
import { UserContext } from '../../contexts/userContext';

const HeaderContainer = styled(Row)`
  width: 100%;
  height: 4rem;
  position: relative;
  z-index: 1;
`;

const HeaderRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem; 
  width: 100%;

  @media ${DEVICES.tablet}{
    padding: 0 2rem; 
  }
`;

const Header = () => {
  const { user } = useContext(UserContext);

  return (
    <HeaderContainer>
      <HeaderRow>
        <Logo />
        { !user && <Link to={ { pathname: '/login' } }> Logga in </Link>}
        { user && <Options user={ user }/> }
      </HeaderRow>
    </HeaderContainer>
  );
};

export default Header;
