import React, { useState, useEffect, useMemo } from 'react';
import { IconVerySad, IconSad, IconHappy, IconVeryHappy } from '../../modules/icons';
import { useParams } from 'react-router-dom';
import { updateResource, getResource } from '../../utils/voteApi';
import { calculateVotes } from '../../utils/calculations';
import { Row, CenteredColumnContainer } from '../../modules/elements';
import { IconContainer } from './styled';
import VoteHeader from './vote-header';

const Vote = () => {
  const { id } = useParams();
  const audio = new Audio('/sounds/plop.mp3');
  const [data, setData] = useState(null);
  const [sound, toggleSound] = useState(false);

  const handleClick = async (property) => {
    const newData = { ...data };
    newData[property] += 1;
    setData(newData);
    if (sound) {
      audio.play();
    }
  };

  const votes = useMemo(() => {
    if (data) return calculateVotes(data);
  }, [data]);

  useEffect(() => {
    const updateSession = async () => {
      await updateResource(id, data);
    };
    const fetchSession = async () => {
      const resource = await getResource(id);

      setData(resource);
    };

    if (!data) {
      fetchSession();
    } else {
      updateSession();
    }
  }, [id, data]);

  if (!data) return null;

  return (
    <>
      <VoteHeader
        numVotes={ votes }
        sound={ sound }
        toggleSound={ toggleSound }
        topic= { data.topic }
        dataId= { id }
      />
      <CenteredColumnContainer>
        <Row>
          <IconContainer onClick={ handleClick.bind(this, 'very_sad_count') }>
            <IconVerySad />
          </IconContainer>
          <IconContainer onClick={ handleClick.bind(this, 'sad_count') }>
            <IconSad/>
          </IconContainer>
          <IconContainer onClick={ handleClick.bind(this, 'happy_count') }>
            <IconHappy />
          </IconContainer>
          <IconContainer onClick={ handleClick.bind(this, 'very_happy_count') }>
            <IconVeryHappy />
          </IconContainer>
        </Row>
      </CenteredColumnContainer>
    </>
  );
};

export default Vote;
