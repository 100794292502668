import React from 'react';
import { Column, Row } from '../../modules/elements';
import PieChart from '../../modules/charts/pie';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Redo } from '../../modules/icons';
import { Headline1 } from '../../style/texts';
import useGetSessionById from '../../hooks/useGetSessionById';

const Headline = styled(Headline1)`
  display: inline-block;
  margin: 0 1rem;
`;

const Element = () => {
  const { id } = useParams();
  const session = useGetSessionById(id);

  if (!session) return null;

  return (
    <Column>
      <Row>
        <Link to={ `/vote/${id}` }>
          <Headline>
            { session && session.topic }
          </Headline>
          <Redo />
        </Link>
      </Row>
      { session && <PieChart data={ session }/>}
    </Column>
  );
};

export default Element;
