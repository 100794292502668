import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Row } from '../../modules/elements';
import { IconVeryHappy } from '../../modules/icons';
import Translation from '../../translation/lang.json';
import { DEVICES } from '../../style/devices';

const OuterContainer = styled(Row)`
  a {
    text-decoration: none;
    color: black;
  }
`;

const LogoText = styled.p`
  font-size: 1rem;
  margin: 0;

  @media ${DEVICES.tablet}{
    font-size: 1.5rem;
  }
`;

const LogoContainer = styled(Row)`
  align-items: center;

  i {
    font-size: 1.5rem;
  }
`;

const Logo = () => {
  return (
    <OuterContainer>
      <Link to={ () => '/' }>
        <LogoContainer>
          <LogoText> { Translation.se.logo.text } </LogoText>
          <IconVeryHappy />
        </LogoContainer>
      </Link>
    </OuterContainer>
  );
};

export default Logo;
