import { useState, useEffect, useContext } from 'react';
import { getCategoriesForUser } from '../utils/voteApi';
import { UserContext } from '../contexts/userContext';

const useGetCategoriesForUser = () => {
  const [categories, setCategories] = useState(null);
  const { user } = useContext(UserContext);

  const formatCategories = (array) => array?.map(({ category }) => (
    {
      value: category ?? '',
      text: category ?? 'Ingen kategori vald'
    })
  );

  useEffect(() => {
    const getCategories = async () => {
      const userCategories = await getCategoriesForUser();
      setCategories([...formatCategories(userCategories)]);
    };
    if (user) {
      getCategories();
    }
  }, [user]);

  return categories;
};

export default useGetCategoriesForUser;
