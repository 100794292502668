import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { isLoggedIn } from '../utils/userApi';

export const UserContext = createContext({ user: null, setUserContext: null });

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const setUserContext = (user) => {
    setUser(user);
  };

  useEffect(() => {
    const checkAuthenticated = async () => {
      const response = await isLoggedIn();
      setUser(response?.authenticated ? response?.user : null);
    };
    checkAuthenticated();
  }, []);

  return (
    <UserContext.Provider value={ { user, setUserContext } }>
      { children }
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.node
};
