import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { resetPassword } from '../../utils/userApi';
import { Button, TYPES } from '../../modules/button';
import { Row, Column } from '../../modules/elements';
import { Input } from '../../modules/input';

const Container = styled(Column)`
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 4rem 0;
`;

const NewPassword = () => {
  const { userEmail, passwordResetToken } = useParams();
  const [message, setMessage] = useState(null);
  const newPassword = useRef(null);
  const newPasswordRepeat = useRef(null);

  const updatePassword = async () => {
    setMessage(null);
    const newPass = newPassword.current.value;
    const newPassRepeat = newPasswordRepeat.current.value;
    if (newPass !== newPassRepeat) {
      setMessage('Det nya lösenordet matchar inte');
      return;
    }
    const { success, error } = await resetPassword(userEmail, passwordResetToken, newPassword.current.value);
    if (success) setMessage(success);
    else setMessage(error);
  };

  return (
    <Container>
      <Column style={ { maxWidth: '400px' } }>
        <Input ref={ newPassword } placeholder="Nytt lösenord" type="password"/>
        <Input ref={ newPasswordRepeat } placeholder="Upprepa nytt lösenord" type="password"/>
        <Row>
          <Button type="submit" action={ TYPES.PRIMARY } onClick={ updatePassword }> Uppdatera lösenord </Button>
        </Row>
      </Column>
      {message}
    </Container>
  );
};

export default NewPassword;
