import styled from 'styled-components';

const Icon = styled.i`
  font-family: "Flaticon";
  font-style: normal;

  @font-face {
    font-family: "Flaticon";
    src: url("/icons/Flaticon.eot");
    src: url("/icons/Flaticon.eot?#iefix") format("embedded-opentype"),
        url("/icons/Flaticon.woff2") format("woff2"),
        url("/icons/Flaticon.woff") format("woff"),
        url("/icons/Flaticon.ttf") format("truetype"),
        url("/icons/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
  }

  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: "Flaticon";
      src: url("/icons/Flaticon.svg") format("svg");
    }
  }

  [class^="flaticon-"]:before, [class*=" flaticon-"]:before,
  [class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
    font-family: Flaticon;
          font-size: 20px;
  font-style: normal;
  margin-left: 20px;

}

  &:before{
    font-size: ${fontSize => fontSize ?? 'inherit'};
  }
`;

const ScalableIcon = styled.img`
  max-width: ${width => width ?? '100%'};
  max-height: ${height => height ?? '100%'};
  object-fit: cover;
`;

export const Add = styled(ScalableIcon).attrs(props => ({
  src: '/icons/add-24px.svg'
}))``;

export const Settings = styled(ScalableIcon).attrs(props => ({
  src: '/icons/settings-24px.svg'
}))``;

export const User = styled(ScalableIcon).attrs(props => ({
  src: '/icons/person-24px.svg'
}))``;

export const Exit = styled(ScalableIcon).attrs(props => ({
  src: '/icons/clear-24px.svg'
}))``;

export const VolumeUp = styled(ScalableIcon).attrs(props => ({
  src: '/icons/volume_up-24px.svg'
}))``;

export const VolumeOff = styled(ScalableIcon).attrs(props => ({
  src: '/icons/volume_off-24px.svg'
}))``;

export const Redo = styled(ScalableIcon).attrs(props => ({
  src: '/icons/redo-24px.svg'
}))``;

export const Done = styled(ScalableIcon).attrs(props => ({
  src: '/icons/done-24px.svg'
}))``;

export const IconVerySad = styled(Icon)`
  padding: 1rem;
  &:before{
    color: red;
    content: "\\f104";
  }
`;
export const IconSad = styled(Icon)`
  padding: 1rem;
  &:before{
    color: orange;
    content: "\\f100";
  }
`;
export const IconMeh = styled(Icon)`
  padding: 1rem;
  &:before{
    color: yellow;
    content: "\\f103";
  }
`;
export const IconHappy = styled(Icon)`
  padding: 1rem;
  &:before{
    color: lightgreen;
    content: "\\f101";
  }
`;
export const IconVeryHappy = styled(Icon)`
  padding: 1rem;
  &:before{
    color: green;
    content: "\\f102";
  }
`;
