import React, { useState, useEffect } from 'react';
import { Column } from '../../modules/elements';
// import { calculateWeekNumber } from '../../utils/calculations';
import useGetSessionsForUser from '../../hooks/useGetSessionsForUser';
import useGetCategoriesForUser from '../../hooks/useGetCategoriesForUser';
import Dropdown from '../../modules/dropdown';
import CreateNew from '../create-new';
import { TopRow, Table, Th, Container } from './styled';
import Session from './session';
import Translation from '../../translation/lang.json';
// import Chart from './chart';

const Statistics = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const categories = useGetCategoriesForUser();
  const { refetch, sessions } = useGetSessionsForUser();

  useEffect(() => {
    refetch(selectedCategory);
  }, [selectedCategory]);

  if (!sessions) return null;

  const renderSessions = (voteSessions) =>
    voteSessions.map((session, index) =>
      <Session
        key={ index }
        session={ session }
        categories= { categories }
        rerender = { refetch }
      />);

  return (
    <Column style={ { marginTop: '6rem' } }>
      <TopRow>
        <CreateNew />
      </TopRow>
      <TopRow>
        { categories && categories.length > 0 &&
          <Dropdown onSelectionChanged={ setSelectedCategory } options={ categories }/>
        }
      </TopRow>
      <Container>
        <Table>
          <thead>
            <tr>
              <Th>{Translation.se.statistics.date}</Th>
              <Th style={ { textAlign: 'center' } }>{Translation.se.statistics.sentiment}</Th>
              <Th>{Translation.se.statistics.topic}</Th>
              <Th>{Translation.se.statistics.category}</Th>
            </tr>
          </thead>
          <tbody>
            { sessions && renderSessions(sessions) }
          </tbody>
        </Table>
      </Container>
    </Column>
  );
};

export default Statistics;
