import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { updateResource } from '../../utils/voteApi';
import { Add, IconVerySad, IconSad, IconHappy, IconVeryHappy } from '../../modules/icons';
import { calculateAverageSentiment } from '../../utils/calculations';
import { Tr, Td, CategoryButton } from './styled';
import { Button, TYPES } from '../../modules/button';
import { Row } from '../../modules/elements';
import Modal from '../../modules/modal';
import Category from '../create-new/category';
import Translation from '../../translation/lang.json';

export const getSentimentIcon = (sentimentValue) => {
  if (sentimentValue < 1.5) return <IconVerySad />;
  if (sentimentValue >= 1.5 && sentimentValue < 2.5) return <IconSad />;
  if (sentimentValue >= 2.5 && sentimentValue < 3.5) return <IconHappy />;
  if (sentimentValue >= 3.5) return <IconVeryHappy />;
};

const formatDate = (session) => {
  const date = new Date(session.created_on);
  const dateString = date.toISOString().slice(0, 10);
  return dateString;
};

const Session = ({ session, categories, rerender }) => {
  const [showChangeCategory, setShowChangeCategory] = useState(false);
  const category = session?.category;
  const [newCategory, setNewCategory] = useState(session?.category);

  if (!session) return null;

  const renderSentiment = (session) => getSentimentIcon(calculateAverageSentiment(session));
  const onClickShowChangeCategory = () => setShowChangeCategory(true);
  const updateNewCategory = (value) => setNewCategory(value);

  const renderModal = () => {
    const onClickUpdateCategory = async () => {
      await updateResource(session.session_id, { ...session, category: newCategory });
      setShowChangeCategory(false);
      rerender();
    };

    const onClickCancel = () => {
      setShowChangeCategory(false);
    };

    return (
      <Modal>
        <Category
          setCategoryCallback={ updateNewCategory }
          initialValue={ session.category }
          categories={ categories }
        />
        <Row>
          <Button onClick={ onClickUpdateCategory } action={ TYPES.PRIMARY }> { Translation.se.statistics.updateCategory } </Button>
          <Button onClick={ onClickCancel } action={ TYPES.SECONDARY }> { Translation.se.statistics.cancel } </Button>
        </Row>
      </Modal>
    );
  };

  return (
    <Tr>
      <Td>
        <Link to={ () => `/summary/${session.session_id}` }>
          {formatDate(session)}
        </Link>
      </Td>
      <Td style={ { textAlign: 'center' } }>
        {renderSentiment(session)}
      </Td>
      <Td>
        <Link to={ () => `/summary/${session.session_id}` }>
          {session.topic}
        </Link>
      </Td>
      <Td>
        <CategoryButton onClick={ onClickShowChangeCategory }>
          { category }
          { !category && <Add /> }
        </CategoryButton>
        {showChangeCategory && renderModal()}
      </Td>
    </Tr>
  );
};

Session.propTypes = {
  session: PropTypes.object.isRequired,
  categories: PropTypes.array,
  rerender: PropTypes.func.isRequired
};

export default Session;
