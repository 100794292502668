const HOST = process.env.REACT_APP_SERVER_HOST;

export const createResource = async (topic, category) => {
  const initialState = {
    very_sad_count: 0,
    sad_count: 0,
    happy_count: 0,
    very_happy_count: 0,
    category
  };
  let id = null;
  await fetch(HOST + '/api/create',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        topic,
        ...initialState
      }),
      credentials: 'include'
    })
    .then(res => res.json())
    .then(res => {
      id = res.id;
    });
  return id;
};

export const updateResource = async (id, resourceData) => {
  await fetch(HOST + `/api/update?id=${encodeURIComponent(id)}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...resourceData
      }),
      credentials: 'include'
    })
    .then(res => {
      if (res.ok) console.info('Saved session');
      else console.info('Failed saving session');
    });
};

export const getResource = async (id) => {
  let resource = null;
  await fetch(HOST + `/api/summary?id=${encodeURIComponent(id)}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
    .then(res => res.json())
    .then(res => { resource = res.response; });
  return resource;
};

export const getSessionsForUser = async (category) => {
  let resource = null;
  const baseUrl = '/api/statistics';
  const url = category ? `${baseUrl}?category=${encodeURIComponent(category)}` : baseUrl;
  await fetch(HOST + url,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
    .then(res => res.json())
    .then(res => { resource = res.response; });
  return resource;
};

export const getCategoriesForUser = async () => {
  let categories = null;
  await fetch(HOST + '/api/get-user-categories',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
    .then(res => res.json())
    .then(res => { categories = res.response; });
  return categories;
};
