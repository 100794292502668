import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Button, TYPES } from '../../modules/button';
import { Row, Column } from '../../modules/elements';
import { Input } from '../../modules/input';
import { initiateReset } from '../../utils/userApi';
import Translation from '../../translation/lang.json';

const Container = styled(Column)`
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 4rem 0;
`;

const Element = () => {
  const [feedback, setFeedback] = useState(null);
  const userEmailInput = useRef(null);

  const handleClick = async () => {
    setFeedback(null);
    const userEmail = userEmailInput.current.value;
    const response = await initiateReset(userEmail);
    if (response.success) setFeedback(Translation.se.forgotPassword.success);
    else setFeedback(Translation.se.forgotPassword.error);
  };

  return (
    <Container>
      <Row>
        { Translation.se.forgotPassword.intro }
      </Row>
      <Column style={ { maxWidth: '600px' } }>
        <Input ref={ userEmailInput } placeholder="Email" type="email"/>
        <Row>
          <Button type="submit" action={ TYPES.PRIMARY } onClick={ handleClick }> { Translation.se.forgotPassword.resetPassword } </Button>
        </Row>
      </Column>
      <Row>
        {feedback}
      </Row>

    </Container>
  );
};

export default Element;
