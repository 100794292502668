import styled, { css } from 'styled-components';
import { DEVICES } from '../../style/devices';

export const Headline1Style = css`
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: normal;
  margin: 0;

  @media ${DEVICES.tablet}{
    font-size: 2rem;
  }
`;

export const Text = styled.p`
    font-size: 1rem;
    line-height: 1.5;
    font-weight: normal;
    margin: 0;
  `;

export const Headline1 = styled.h1`
  ${Headline1Style}
`;
